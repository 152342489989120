<template>
<f7-app :params="f7params" >

  <!-- Left panel with cover effect-->
  <f7-panel left cover theme-dark>
    <f7-view>
      <f7-page>
        <f7-navbar title="Left Panel"></f7-navbar>
        <f7-block>Left panel content goes here</f7-block>
      </f7-page>
    </f7-view>
  </f7-panel>


  <!-- Right panel with reveal effect-->
  <!--<f7-panel right reveal theme-dark>-->
    <!--<f7-view>-->
      <!--<f7-page>-->
        <!--<f7-navbar title="Right Panel"></f7-navbar>-->
        <!--<f7-block>Right panel content goes here</f7-block>-->
      <!--</f7-page>-->
    <!--</f7-view>-->
  <!--</f7-panel>-->


  <!-- Views/Tabs container -->
  <f7-views tabs class="safe-areas">
    <!-- Tabbar for switching views-tabs -->
    <f7-toolbar tabbar labels bottom>
      <f7-link tab-link="#view-home" tab-link-active icon-ios="f7:house_fill" icon-aurora="f7:house_fill" icon-md="material:home" text="Home"></f7-link>
      <f7-link tab-link="#view-projects" icon-ios="f7:square_list_fill" icon-aurora="f7:square_list_fill" icon-md="material:view_list" text="Projecten"></f7-link>
      <f7-link tab-link="#view-settings" icon-ios="f7:gear" icon-aurora="f7:gear" icon-md="material:settings" text="Instellingen"></f7-link>
    </f7-toolbar>

    <!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
    <f7-view id="view-home" main tab tab-active url="/"></f7-view>

    <!-- Catalog View -->
    <f7-view id="view-projects" name="projects" tab url="/projects/"></f7-view>

    <!-- Settings View -->
    <f7-view id="view-settings" name="settings" tab url="/settings/"></f7-view>

  </f7-views>


  <!-- Popup -->
  <f7-popup id="my-popup">
    <f7-view>
      <f7-page>
        <f7-navbar title="Popup">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <p>Popup content goes here.</p>
        </f7-block>
      </f7-page>
    </f7-view>
  </f7-popup>

  <f7-login-screen id="my-login-screen">
    <f7-view>
      <f7-page login-screen>
        <f7-login-screen-title>Login</f7-login-screen-title>
        <f7-list form>
          <f7-list-input
            type="text"
            name="username"
            placeholder="Your username"
            :value="username"
            @input="username = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            type="password"
            name="password"
            placeholder="Your password"
            :value="password"
            @input="password = $event.target.value"
          ></f7-list-input>
        </f7-list>
        <f7-list>
          <f7-list-button title="Sign In" @click="doLogin"></f7-list-button>
          <f7-block-footer>
            Some text about login information.<br>Click "Sign In" to close Login Screen
          </f7-block-footer>
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-login-screen>
</f7-app>
</template>
<script>

  import routes from '../js/routes.js';
  import {store} from './../store/store';

  export default {
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: 'Boonzaaijer', // App name
          theme: 'ios', // Automatic theme detection
          iosTranslucentBars: false,
          iosTranslucentModals: false,
          // App root data
          data: function () {
            return {

                store: store,

              // Demo products for Catalog section
              products: [
                {
                  id: '1',
                  title: 'Apple iPhone 8',
                  description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi tempora similique reiciendis, error nesciunt vero, blanditiis pariatur dolor, minima sed sapiente rerum, dolorem corrupti hic modi praesentium unde saepe perspiciatis.'
                },
                {
                  id: '2',
                  title: 'Apple iPhone 8 Plus',
                  description: 'Velit odit autem modi saepe ratione totam minus, aperiam, labore quia provident temporibus quasi est ut aliquid blanditiis beatae suscipit odio vel! Nostrum porro sunt sint eveniet maiores, dolorem itaque!'
                },
                {
                  id: '3',
                  title: 'Apple iPhone X',
                  description: 'Expedita sequi perferendis quod illum pariatur aliquam, alias laboriosam! Vero blanditiis placeat, mollitia necessitatibus reprehenderit. Labore dolores amet quos, accusamus earum asperiores officiis assumenda optio architecto quia neque, quae eum.'
                },
              ]
            };
          },

          // App routes
          routes: routes,
        },

        // Login screen data
        username: '',
        password: '',
      }
    },
    methods: {
      doLogin() {
          const formData = new FormData();
          formData.append("email", this.username);
          formData.append("password", this.password);
//          formData.append("user_type", 1);

          var that = this;

          this.$http.post('/auth/login', formData)
              .then(request => that.loginSuccessful(request))
              .catch((err) => that.loginFailed(err))

      },
      loginSuccessful (req) {
          const app = this.$f7;
          console.log(req, req.data.user.api_key);
          if (!req.data.user.api_key) {
//              this.loginFailed()
//              return
          }

          localStorage.token = req.data.user.api_key
          this.$http.defaults.headers.common = {'Authorization': "Bearer " + localStorage.token}
          this.error = false

          app.loginScreen.close();
          app.views.main.router.navigate('/', {reloadCurrent: true});


//          this.$router.replace(this.$route.query.redirect || '/authors')
      },
      loginFailed (err) {
          console.log('error', err);
          this.$f7.dialog.alert('Login Failed');
          delete localStorage.token
      }
    },
    mounted() {
      this.$f7ready((f7) => {
          const app = f7;

          if (!localStorage.token) {
              app.loginScreen.open('#my-login-screen');
          }

        // Call F7 APIs here
      });
    }
  }
</script>