<template>
  <f7-page name="project-details">
    <f7-navbar :title="project.title" back-link="Back"></f7-navbar>
    <f7-block-title>About {{project.title}}</f7-block-title>
    <f7-block strong>
      {{project.title}}
    </f7-block>
  </f7-page>
</template>
<script>
  export default {
    data: function () {
      var projectId = this.$f7route.params.id;
      var currentProject;
      this.$f7.data.store.state.projects.forEach(function (project) {
        if (project.id === projectId) {
          currentProject = project;
        }
      });
      return {
        project: currentProject,
      };
    }
  };
</script>
