import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        user: {
            name: ''
        },
        config: {

        },
        workdays: {},
        projects: {}
    },
    actions: {

        fetchWorkdays(context, data) {

            return new Promise((resolve, reject) => {

                console.log("fetchWorkdays", data);
                const formData = new FormData();
                if (data && data.fromDate) formData.append("from", data.fromDate);
                if (data && data.toDate) formData.append("to", data.toDate);

                data.$http.post('/my/workdays/overview', formData)
                    .then((req) => {
                        var workdays = [];
                        console.log(req.data);
                        for (var i = 0; i < req.data.workdays.length; i++) {
                            var thisWorkday = req.data.workdays[i];
                            workdays.push(thisWorkday);
                            this.state.workdays[thisWorkday.date] = thisWorkday;
                        }
                        resolve(workdays);
                    })
                    .catch((err) => reject(err))
            })
        },

        setWorkday(date, workday) {
            this.state.workdays[date] = workday;

        },

        fetchProjects(context, data) {

            return new Promise((resolve, reject) => {

                if (this.state.projects.length > 0) {
                    resolve(this.state.projects);
                    return;
                }

                data.$http.post('/my/projects/overview')
                    .then((req) => {
                        var projects = [];
                        for (var i = 0; i < req.data.Project.length; i++) {
                            var thisProject = req.data.Project[i];
                            this.state.projects[thisProject.id] = thisProject;
                        }
                        resolve(this.state.projects);
                    })
                    .catch((err) => reject(err));
            })
        }

    },
    defaults: {
        workday: {
            starttime: '09:00',
            endtime: '17:30',
            mornBreak: 0,
            lunch: 0,
            afternBreak: 0,
            status: 0,
            comments: '',
        }
    },



});