<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left>
        <f7-link @click="backToWorkday" icon-f7="chevron_left">Terug</f7-link>
      </f7-nav-left>

      <div v-if="workdayProject.project_id"> Bewerken tijdregistratie <br> daguur:{{workdayProject.hours_day}}  </div>
      <div v-else> Nieuwe tijdregistratie {{workdayProject.id}}    </div>
      <f7-nav-right>
        <f7-link icon-f7="plus"></f7-link>
      </f7-nav-right>
    </f7-navbar>


    <f7-toolbar :position="'top'" style="height: 60px;">
      <div style="flex: 0 0 100%; padding-bottom: 5px;  padding-top: 6px;">
        <f7-row style="padding: 0px 0px;">
          <f7-button class="col" style="flex: 0 0 40px; text-align: center; height: 40px; padding: 5px; " @click="alert('test')"><f7-icon f7="chevron_left"></f7-icon></f7-button>
          <f7-col class="text-align-center" style="padding-top: 10px; font-size: 12pt; font-weight: 400; line-height: 1.1em; color: #555;">
            {{ workday.date | humanDate }}
          </f7-col>
          <f7-button class="col" style="flex: 0 0 40px; text-align: center; height: 40px; padding: 5px;" @click="alert('test')"><f7-icon f7="chevron_right"></f7-icon></f7-button>
        </f7-row>
      </div>
      <!--<f7-button class="col" fill @click="dateWeekAdd(-1)">&lt; Vorige</f7-button>-->
      <!--<f7-button class="col">WK {{weekNumber[1]}} {{weekNumber[0]}}</f7-button>-->
      <!--<f7-button class="col" fill @click="dateWeekAdd(1)">Volgende &gt;</f7-button>-->

    </f7-toolbar>

    <!--<f7-block-title>Werktijden</f7-block-title>-->

    <f7-list>

      <f7-list-input
              label="Project"
              type="select"
              :value="(workdayProject.id) ? workdayProject.id : ''"
              @input="workdayProject.id = $event.target.value"
      >
        <option value="">---</option>
        <option v-for="(project, projectId) in projects" v-bind:value="project.id">{{ project.title }}</option>
      </f7-list-input>

      <f7-list-input
              label="Daguren"
              type="select"
              :value="(workdayProject.hours_day) ? workdayProject.hours_day : '0'"
              @input="workdayProject.hours_day = $event.target.value"
      >
        <option v-for="time in times" v-bind:value="time.value">{{ time.label }}</option>
      </f7-list-input>


      <f7-list-input
              label="Nachturen"
              type="select"
              :value="(workdayProject.hours_night) ? workdayProject.hours_night : '0'"
              @input="workdayProject.hours_night = $event.target.value"
      >
        <option v-for="time in times" v-bind:value="time.value">{{ time.label }}</option>
      </f7-list-input>


      <f7-list-input
              label="Laad uren"
              type="select"
              :value="(workdayProject.hours_loading) ? workdayProject.hours_loading : '0'"
              @input="workdayProject.hours_loading = $event.target.value"
      >
        <option v-for="time in times" v-bind:value="time.value">{{ time.label }}</option>
      </f7-list-input>

      <f7-list-item
              title="Gereden met eigen auto"
      >
        <f7-toggle slot="after"></f7-toggle>
      </f7-list-item>

      <f7-list-item
              title="Gereden met aanhanger"
      >
        <f7-toggle slot="after"></f7-toggle>
      </f7-list-item>



<!--      <f7-list-input-->
<!--              :value="workday.comments"-->
<!--              @input="workday.comments = $event.target.value"-->
<!--              type="textarea"-->
<!--              label="Opmerkingen"-->
<!--              placeholder="Dag opmerkingen"-->
<!--              resizable-->
<!--      ></f7-list-input>-->
    </f7-list>

    <f7-block >
      <f7-row tag="p">
        <!--<f7-button class="col">Button</f7-button>-->
        <f7-button class="col" fill @click="saveWorkdayProject">Opslaan</f7-button>
      </f7-row>
    </f7-block>


  </f7-page>
</template>
<script>

    import routes from '../js/routes.js';
    import moment from 'moment'

    export default {
        data() {
            var times = [];

            for (var i = 0; i < 24; i++) {
              for (var j = 0; j < 60; j += 15) {
                times.push({
                  value: (i * 60 + j) / 60,
                  label: i.toString().padStart(2,'0')+':'+j.toString().padStart(2,'0')
                })
              }
            }

            return {
                times: times,
                projects: this.$f7.data.store.state.projects,
                defaultWorkday: {
                    starttime: '09:00',
                    endtime: '17:30',
                    mornBreak: 1,
                    lunch: 1,
                    afternBreak: 1,
                    status: 0,
                    comments: '',
                }
            }
        },
        props: {
            workday: {
                type: Object,
                default: function () {
                    return {
                        starttime: '09:00',
                        endtime: '17:30',
                        mornBreak: 1,
                        lunch: 1,
                        afternBreak: 1,
                        status: 0,
                        comments: '',
                    }
                }
            },
            workdayProject: {
              type: Object,
              default: function () {
                return {
                  id: 0,
                  hours_day: 0,
                  hours_night: 0,
                  hours_loading: 0,
                  own_car: false,
                  trailer: false,
                }
              }
            }
        },
        methods: {
            init() {

            },

            saveWorkdayProject () {

                const formData = new FormData();
                formData.append("date", moment(this.workday.date).format('DD-MM-YYYY'));
                formData.append("project_id", (this.workdayProject.id) ? this.workdayProject.id : 0);
                formData.append("dayHours", (this.workdayProject.hours_day) ? this.workdayProject.hours_day : 0);
                formData.append("nightHours", (this.workdayProject.hours_night) ? this.workdayProject.hours_night : 0);
                formData.append("loadHours", (this.workdayProject.hours_loading) ? this.workdayProject.hours_loading : 0);
                formData.append("with_trailer",  "true");
                formData.append("own_car",  "true");

                var that = this;
                this.$http.post('/my/WorkdayProjects/save', formData)
                    .then(request => that.saveWorkdayProjectSuccess(request))
                    .catch((err) => that.saveWorkdayProjectError(err))


            },
            saveWorkdayProjectSuccess (req) {
              this.workday.tracked_projects = req.data;
              this.$f7.data.store.dispatch("setWorkday", { workday: this.workday, date: this.workday.date, $http: this.$http });
                this.$f7.views.main.router.back('/workday/', {
                  force: true,
                  props: {
                    workday: this.workday
                  }
                });
            },
            saveWorkdayProjectError (err) {
                alert(err);
            },
            backToWorkday () {

              this.$f7.views.main.router.back('/workday/', {
                force: true,
                props: {
                  workday: this.workday
                }
              });
            }


        },
        mounted() {
            console.log("Mounted Workday: ",this.props, this);
            this.$f7ready((f7) => {
              this.init();

            });
        }
    }
</script>
