<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title @click="refreshApp()"><img src="static/images/bz-icon-trans.png" style="width: 16px; height: 16px; position: relative; top: 1px;" valign="center" /> Boonzaaijer</f7-nav-title>

    </f7-navbar>

    <f7-toolbar :position="'top'" style="height: 60px;">
        <div style="flex: 0 0 100%; padding-bottom: 5px;  padding-top: 6px;">
        <f7-row style="padding: 0px 0px;">
          <f7-button class="col" style="flex: 0 0 40px; text-align: center; height: 40px; padding: 5px; " @click="dateWeekAdd(-1)"><f7-icon f7="chevron_left"></f7-icon></f7-button>
          <f7-col class="text-align-center" style="padding-top: 4px; font-size: 12pt; font-weight: 400; line-height: 1.1em; color: #555;">
            {{ startDate | showDate(0,'D MMM') }} t/m {{ startDate | showDate(6,'D MMM') }}<br/>
            <span style="font-size: 9pt; font-weight: 600; color: #888;">
              Week {{weekNumber[1]}} {{weekNumber[0]}}
            </span>
          </f7-col>
          <f7-button class="col" style="flex: 0 0 40px; text-align: center; height: 40px; padding: 5px;" @click="dateWeekAdd(1)"><f7-icon f7="chevron_right"></f7-icon></f7-button>
        </f7-row>
        </div>
        <!--<f7-button class="col" fill @click="dateWeekAdd(-1)">&lt; Vorige</f7-button>-->
        <!--<f7-button class="col">WK {{weekNumber[1]}} {{weekNumber[0]}}</f7-button>-->
        <!--<f7-button class="col" fill @click="dateWeekAdd(1)">Volgende &gt;</f7-button>-->

    </f7-toolbar>
    <!-- Page content-->
    <!--<f7-block >-->

        <!--<f7-row tag="p">-->

        <!--</f7-row>-->
    <!--</f7-block>-->
    <!---->
    <f7-block>
      <!--<p>{{ user.name }}</p>-->
      <f7-row>
        <f7-col>
          <strong>{{ user.name }}</strong><br/>
          <div v-if="weekdayTotals.total_workdays">
            {{ weekdayTotals.total_workdays }} dagen gewerkt.
          </div>
          <div v-if="weekdayTotals.total_km">
            {{ weekdayTotals.total_km }} km gereden.
          </div>

          <div v-if="weekNumber.join('-') != getWeekNumber(new Date()).join('-')" style="margin-top: 5px; display: inline-block;">
            <f7-button fill @click="thisWeek()">Deze week</f7-button>
          </div>


        </f7-col>
        <f7-col class="text-align-center">
          <f7-gauge
                  type="semicircle"
                  :value="(weekdayTotals.total_hours / 40)"
                  :value-text="(weekdayTotals.total_hours > 0) ? weekdayTotals.total_hours.toLocaleString('nl', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' uur' : ''"
                  value-text-color="#ff9800"
                  :valueFontSize="25"
                  :valueFontWeight="700"
                  :border-width="10"
                  border-color="#ff9800"
          ></f7-gauge>
        </f7-col>

      </f7-row>

    </f7-block>

    <!--<f7-block-title>Navigation</f7-block-title>-->
    <!--<f7-list>-->
      <!--<f7-list-item link="/workday/" title="Vandaag"></f7-list-item>-->
      <!--&lt;!&ndash;<f7-list-item link="/form/" title="Form"></f7-list-item>&ndash;&gt;-->
    <!--</f7-list>-->

    <!--<f7-block-title>Dagen van deze week</f7-block-title>-->
    <div v-if="!loading">

      <f7-list media-list>
        <f7-list-item v-for="workday in weekdays" v-bind:key="workday.id"
                link="#"

                @click="openWorkday(workday)"
                v-bind:after="(workday.hours_total) ? parseFloat(workday.hours_total).toLocaleString('nl', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' uur' : ''"

                v-bind:text="workday.comments"
        >

          <f7-icon v-if="workday.id" slot="media" f7="calendar_circle" size="38" style="color: #f80;"></f7-icon>
          <f7-icon v-if="!workday.id" slot="media" f7="calendar_circle" size="38" style="color: #aaa;"></f7-icon>


          <span v-if="workday.moment.isAfter(today)" style="color: #aaa; font-size: 11pt; font-weight: 600;" slot="title">{{ workday.date | humanDate }}</span>
          <span v-if="!workday.moment.isAfter(today)" style="font-size: 11pt; font-weight: 600;" slot="title">{{ workday.date | humanDate }}</span>

          <span slot="subtitle">
            <span v-if="workday.starttime" style="color: #888; font-weight: 600;"><f7-icon f7="clock" size="12" style="position: relative; top: -1px;"></f7-icon> {{ workday.starttime.substr(0,5) + ' - ' + workday.endtime.substr(0,5) }}</span>
            <span v-if="!workday.starttime" style="color: #aaa">Nog geen tijden opgegeven</span>

          </span>

          <!--<f7-block>-->
          <!--<f7-row style="padding: 15px; font-size: 12pt;">-->
            <!--<f7-col style="flex: 0 0 20px;">-->
              <!--<f7-icon f7="calendar_circle" size="22"></f7-icon>-->
            <!--</f7-col>-->
            <!--<f7-col style="flex: 0 0 20px;">-->
              <!--{{ workday.date | humanDate }}-->
            <!--</f7-col>-->
            <!--<f7-col style="flex: 0 0 70px; text-align: right;">-->
              <!--{{ (workday.hours_total) ? parseFloat(workday.hours_total).toLocaleString('nl', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' uur' : '' }}-->
            <!--</f7-col>-->
          <!--</f7-row>-->
          <!--</f7-block>-->

          <!--<f7-swipeout-actions left>-->
            <!--<f7-swipeout-button overswipe color="green" @click="reply">Reply</f7-swipeout-button>-->
            <!--<f7-swipeout-button color="blue" @click="forward">Forward</f7-swipeout-button>-->
          <!--</f7-swipeout-actions>-->
          <!--<f7-swipeout-actions right>-->
            <!--&lt;!&ndash;<f7-swipeout-button @click="more">More</f7-swipeout-button>&ndash;&gt;-->
            <!--&lt;!&ndash;<f7-swipeout-button color="orange" @click="mark">Mark</f7-swipeout-button>&ndash;&gt;-->
            <!--<f7-swipeout-button delete overswipe confirm-text="Are you sure you want to delete this item?">Delete</f7-swipeout-button>-->
          <!--</f7-swipeout-actions>-->
        </f7-list-item>

      </f7-list>
    </div>


    <div v-if="loading" class="text-align-center">
      <f7-list media-list>
        <f7-list-item
                v-for="n in 5"
                :key="n"
                :class="`skeleton-text skeleton-effect-blink`"
                style="text-align: left;"
                title="Full Name"
                subtitle="Nog geen tijden opgegeven"
                after="14:00 - 17:00">
          <f7-skeleton-block style="width: 20px; height: 20px; border-radius: 50%" slot="media"></f7-skeleton-block>
        </f7-list-item>
      </f7-list>
    </div>

    <!--<f7-block-title>Modals</f7-block-title>-->
    <!--<f7-block strong>-->
      <!--<f7-row>-->
        <!--<f7-col width="50">-->
          <!--<f7-button fill raised popup-open="#my-popup">Popup</f7-button>-->
        <!--</f7-col>-->
        <!--<f7-col width="50">-->
          <!--<f7-button fill raised login-screen-open="#my-login-screen">Login Screen</f7-button>-->
        <!--</f7-col>-->
      <!--</f7-row>-->
    <!--</f7-block>-->

    <!--<f7-block-title>Panels</f7-block-title>-->
    <!--<f7-block strong>-->
      <!--<f7-row>-->
        <!--<f7-col width="50">-->
          <!--<f7-button fill raised panel-open="left">Left Panel</f7-button>-->
        <!--</f7-col>-->
        <!--<f7-col width="50">-->
          <!--<f7-button fill raised panel-open="right">Right Panel</f7-button>-->
        <!--</f7-col>-->
      <!--</f7-row>-->
    <!--</f7-block>-->

    <!--<f7-list>-->
      <!--<f7-list-item-->
        <!--title="Dynamic (Component) Route"-->
        <!--link="/dynamic-route/blog/45/post/125/?foo=bar#about"-->
      <!--&gt;</f7-list-item>-->
      <!--<f7-list-item-->
        <!--title="Default Route (404)"-->
        <!--link="/load-something-that-doesnt-exist/"-->
      <!--&gt;</f7-list-item>-->
      <!--<f7-list-item-->
        <!--title="Request Data & Load"-->
        <!--link="/request-and-load/user/123456/"-->
      <!--&gt;</f7-list-item>-->
    <!--</f7-list>-->

  </f7-page>
</template>
<script>

    import routes from '../js/routes.js';
    import moment from 'moment'

    export default {
        data() {
            return {
                weekNumber: [2019,1],
                startDate: new Date(),
                user: this.$f7.data.store.state.user,
                workdays: [],
                loading: true,
                today: moment()
            }
        },
        filters: {
          showDate: function (value, addDays, dateFormat) {
            if (!value) return ''
            var theDate = moment(value);

            return theDate.add(addDays, 'days').format(dateFormat);
          }
        },
        computed: {
            currentDateFrom: function () {
              this.startDate;
              return moment(this.startDate);
            },
            currentDateTo: function () {
              this.startDate;
              return moment(this.startDate);
            },
            weekdays: function () {
                this.workdays;
                this.weekNumber;
                var arr = [];
                var d = new Date(Date.UTC(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()));
                for (var i = 1; i < 7; i++) {
                    var dateStr = moment(d).format('YYYY-MM-DD');
                    if (this.$f7.data.store.state.workdays[dateStr]) {
                        var obj = this.$f7.data.store.state.workdays[dateStr];
                    } else {
                        var obj = {
                            starttime: false,
                            endtime: false,
                            mornBreak: 1,
                            lunch: 1,
                            afternBreak: 1,
                            status: 0,
                            comments: '',
                            tracked_projects: []
                        }
                        obj.date = dateStr;
                    }

                    obj.moment = moment(d);

                    arr.push(obj)
                    d = moment(d).add(1, 'days');

                }
                return arr;
            },
            weekdayTotals: function () {
                this.workdays;
                var total = 0;
                var total_workdays = 0;
                var total_km = 0;
                for (var i = 0; i < this.weekdays.length; i++) {
                    var workday = this.weekdays[i];
                    if (workday.hours_total) {
                        total += parseFloat(workday.hours_total);
                        total_workdays += 1;
                        if (this.weekdays[i].tracked_projects) {
                          for (var j = 0; j < this.weekdays[i].tracked_projects.length; j++) {
                            total_km += parseInt(this.weekdays[i].tracked_projects[j].travel_km);
                          }
                        }

                    }
                }

                if (this.loading) {
                  total = 0;
                  total_workdays = 0;
                  total_km = 0;
                }

                return {
                    total_hours: total,
                    total_workdays: total_workdays,
                    total_km: total_km
                }

            }
        },
        props: ['loadingBeforeInit'],
        methods: {
            init() {
                if (localStorage.token) {

                  this.loading = true;

                    if (this.$f7.data.store.state.currentStartDate) {
                        d = this.$f7.data.store.state.currentStartDate;

                        var day = d.getDay(),
                              diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday

                       this.startDate =  new Date(d.setDate(diff));

                        this.weekNumber = this.getWeekNumber(this.startDate);
                    } else {
                        var d = new Date();
                        this.weekNumber = this.getWeekNumber(d);

                        d = new Date();
                        var day = d.getDay(),
                            diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
                        this.startDate =  new Date(d.setDate(diff));

                        // var sdm = moment();

                        // this.startDate.startOf('week');
                    }

                    var that = this;

                    if (this.loadingBeforeInit) {
                      this.loadingBeforeInit.then(() => { that.initFetchAll(); } );
                    } else {
                      that.initFetchAll();
                    }



                }
            },
            initFetchAll () {

              var that = this;
              this.$http.get('/user')
                      .then(request => that.getUserSuccess(request))
                      .catch((err) => that.getUserFailed(err));

              this.$f7.data.store.dispatch("fetchProjects", {$http: this.$http });

              this.fetchOverview();

            },
            getUserSuccess (req) {
                // console.log(req, this);
                const app = this.$f7;
                app.data.store.state.user = req.data.user;
                app.data.store.state.config = req.data.config;

                // console.log(app.data);

                this.user = this.$f7.data.store.state.user;
                this.$forceUpdate();

            },
            getUserFailed (err) {
                console.log(err);
            },
            fetchOverview() {

              this.loading = true;
              var that = this;

              var fromDate = moment(this.startDate).format('YYYY-MM-DD');
              var toDateCalc = new Date(Date.UTC(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()));
              toDateCalc.setDate(toDateCalc.getDate() + (7));

              console.log("Fetch Workdays");
              var toDate = moment(toDateCalc).format('YYYY-MM-DD');
              this.$f7.data.store.dispatch("fetchWorkdays", {fromDate: fromDate, toDate: toDate, $http: that.$http }).then(
                      (workdays) =>  {
                          that.workdays = workdays;
                          that.loading = false;
                          that.$forceUpdate();
                        }
              ).catch((err) =>  {
                console.log("Error: ",err);
              });


            },
            //
            // getProjectOverviewSuccess (req) {
            //   console.log(req, this);
            //
            //   for (var i = 0; i < req.data.Project.length; i++) {
            //     var thisProject = req.data.Project[i];
            //
            //     this.$f7.data.store.state.projects[thisProject.id] = thisProject;
            //   }
            //
            //   console.log(this.$f7.data.store.state.workdays);
            //   this.$forceUpdate();
            //
            // },
            // getProjectOverviewFailed (err) {
            //   console.log(err);
            // },

            openWorkday(workday) {
                this.$f7.views.main.router.navigate('/workday/', {
//                    reloadCurrent: true,

                    props: {
                        workday: workday
                    }

                });
            },
            thisWeek () {
                var d = new Date();
                this.weekNumber = this.getWeekNumber(d);

                d = new Date();
                var day = d.getDay(),
                        diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
                this.startDate =  new Date(d.setDate(diff));
                this.fetchOverview();
            },
            dateWeekAdd (plus) {
                this.workdays = [];
                this.startDate.setDate(this.startDate.getDate() + (7 * plus));
                this.weekNumber = this.getWeekNumber(this.startDate);
                this.$f7.data.store.state.currentStartDate = this.startDate;
                this.fetchOverview();

            },
            refreshApp () {
              window.location.reload();
            },
            getWeekNumber(d) {
                  // Copy date so don't modify original
                  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
                  // Set to nearest Thursday: current date + 4 - current day number
                  // Make Sunday's day number 7
                  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
                  // Get first day of year
                  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
                  // Calculate full weeks to nearest Thursday
                  var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
                  // Return array of year and week number
                  return [d.getUTCFullYear(), weekNo];
            }
        },
        mounted() {
          var that = this;
            this.$f7ready((f7) => {

                this.init();
                // Call F7 APIs here

            });
        }
    }
</script>
