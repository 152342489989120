<template>
  <f7-page name="catalog">
    <f7-navbar title="Projecten"></f7-navbar>
    <f7-list>
      <f7-list-item
        v-for="(project) in projects"
        :key="project.id"
        :title="project.title"
        :link="`/project/${project.id}/`"
      ></f7-list-item>
    </f7-list>
  </f7-page>
</template>
<script>
  export default {
    data: function () {
      return {
        projects: [],
      };
    },
    methods: {
      init () {
        var that = this;
        this.$f7.data.store.dispatch("fetchProjects", { $http: this.$http }).then(
                (projects) =>  {
                  that.projects = projects;
                  that.$forceUpdate();
                }
        ).catch((err) =>  {
          console.log("Error: ",err);
        });

      }
    },
    mounted() {
      this.$f7ready((f7) => {

        // Call F7 APIs here
        this.init();
      });
    }
  };
</script>
