// Import Vue
import Vue from 'vue';
import { store } from './../store/store';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.scss';

// Import App Component
import App from '../components/app.vue';

import axios from './axios';

import moment from 'moment'
import 'moment/locale/nl'

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

Vue.prototype.$http = axios;

moment.locale('nl');

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

Vue.filter('humanDate', function(value) {
    if (value) {

        if (typeof value == "string") {

            // var theDateSplit = value.split('-');
            // if (theDateSplit[0].length == 4) {
            //     var d = new Date(Date.UTC(theDateSplit[0], theDateSplit[1]-1, theDateSplit[2]));
            //     value = d;
            // }
        }
        return capitalize(moment(String(value)).format('dddd D MMMM'))
    }
});


// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),
  axios,
    store,
  // Register App Component
  components: {
    app: App
  },
});

