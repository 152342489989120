<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar>

      <f7-nav-left>
        <f7-link @click="saveWorkday" icon-f7="chevron_left">Terug</f7-link>
      </f7-nav-left>

      <f7-nav-title>{{ workday.date | humanDate }}</f7-nav-title>

      <f7-nav-right>
        <f7-link @click="addProject" icon-f7="plus"></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <!--<f7-block-title>Werktijden</f7-block-title>-->

    <div v-if="!saveLoading">
      <f7-block>
        <f7-row>
          <f7-col>
            <f7-input v-bind:wrap="false" style="font-size: 14pt; padding: 5px;"
                    :value="(workday.starttime) ? workday.starttime.substr(0,5) : '09:00'"
                    @input="workday.starttime = $event.target.value"
                    label="Starttijd"
                    type="select"
            >
              <option v-for="time in times" v-bind:value="time">{{ time }}</option>

            </f7-input>
          </f7-col>
          <f7-col class="text-align-center" style="color: #888; font-size: 14pt; padding: 2px;">
            van - tot
          </f7-col>
          <f7-col>
            <f7-input v-bind:wrap="false" style="font-size: 14pt; padding: 5px;"
                    :value="(workday.endtime) ? workday.endtime.substr(0,5) : '17:30'"
                    @input="workday.endtime = $event.target.value"
                    label="Eindtijd"
                    type="select"
            >
              <option v-for="time in times" v-bind:value="time">{{ time }}</option>
            </f7-input>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col class="text-align-center" style="padding-top: 20px;">
            <f7-checkbox
                    value="1"
                    :checked="workday.mornBreak == 1 ? true : false"
                    @change="workday.mornBreak = $event.target.checked ? 1 : 0"
                  ></f7-checkbox>
            <div style="font-size: 9pt; padding-top: 5px;">
              Kleine pauze<br/>(15 min)
            </div>
          </f7-col>
          <f7-col class="text-align-center" style="padding-top: 20px;">
            <f7-checkbox
                    value="1"
                    :checked="workday.lunch == 1 ? true : false"
                    @change="workday.lunch = $event.target.checked ? 1 : 0"
            ></f7-checkbox>
            <div style="font-size: 9pt; padding-top: 5px;">
              Lunchpauze<br/>(30 min)
            </div>
          </f7-col>
          <f7-col class="text-align-center" style="padding-top: 20px;">
            <f7-checkbox
                    value="1"
                    :checked="workday.afternBreak == 1 ? true : false"
                    @change="workday.afternBreak = $event.target.checked ? 1 : 0"
            ></f7-checkbox>
            <div style="font-size: 9pt; padding-top: 5px;">
              Middagpauze<br/>(15 min)
            </div>
          </f7-col>
        </f7-row>
      </f7-block>

      <f7-list media-list>
        <f7-list-item v-for="(trackedProject,index) in trackedProjects" v-bind:key="trackedProject.id" @click="editProject(index)">

          <f7-icon slot="media" f7="rectangle_fill_badge_checkmark" size="38" style="color: #14785f;"></f7-icon>

          <span style="font-size: 11pt; font-weight: 600;" slot="title">{{ trackedProject.project.title }}</span>

          <span slot="subtitle">

            <div  style="float:left;color: #888; font-weight: 600; padding-right: 10px;width:110px"><f7-icon f7="clock" size="12" style="position: relative; top: -1px;"></f7-icon> {{ trackedProject.hours_day }} uur</div>
            <div  style="float:left;color: #888; font-weight: 600;width:60px"><f7-icon f7="car_fill" size="12" style="position: relative; top: -1px;"></f7-icon> {{ trackedProject.travel_km * trackedProject.own_car }} km</div>
            <div v-if="index==0" style="float:left;color: #888; font-weight: 600; padding-right: 10px;width:60px;"><f7-icon  size="12" style="position: relative; top: -1px;"></f7-icon> {{min2time(trackedProject.travel_minutes)}} uur </div>
  <!--
              <span v-if="trackedProject.hours_day" style="color: #888; font-weight: 600; padding-right: 10px;"><f7-icon f7="clock" size="12" style="position: relative; top: -1px;"></f7-icon> {{ trackedProject.hours_day }} uur</span>
              <span v-if="trackedProject.travel_km" style="color: #888; font-weight: 600;"><f7-icon f7="car_fill" size="12" style="position: relative; top: -1px;"></f7-icon> {{ trackedProject.travel_km }} km</span>
  -->
          </span>
          </f7-list-item>

          <f7-list-item v-if = "trackedProjects.length>0">

          <f7-icon slot="media" f7="rectangle_paperclip" size="38" style="color: #14785f"></f7-icon>
          <span style="font-size: 11pt; font-weight: 600;" slot="title">reis naar huis</span>
          <span slot="subtitle">
              <div  style="float:left;color: #888; font-weight: 600; padding-right: 10px;width:110px">&nbsp;</div>
              <div  style="float:left;color: #888; font-weight: 600;width:60px"><f7-icon f7="car_fill" size="12" style="position: relative; top: -1px;"></f7-icon> {{travel_km_home}} km</div>
              <div  style="float:left;color: #888; font-weight: 600; padding-right: 10px;width:60px;"><f7-icon  size="12" style="position: relative; top: -1px;"></f7-icon> {{min2time(travel_min_home)}} uur</div>
          </span>
          </f7-list-item>

          <f7-list-item v-if = "trackedProjects.length>0">

          <f7-icon slot="media" f7="money_euro_circle" size="38" style="color: #ccc"></f7-icon>
          <span style="font-size: 11pt; font-weight: 600;" slot="title">&nbsp;</span>
          <span slot="subtitle">
              <div  style="float:left;color: #888; font-weight: 600; padding-right: 10px;width:110px">&nbsp;</div>
              <div  style="float:left;color: #888; font-weight: 600;width:60px"><f7-icon f7="car_fill" size="12" style="position: relative; top: -1px;"></f7-icon> {{travel_km_home}} km</div>
              <div  style="float:left;color: #888; font-weight: 600; padding-right: 10px;width:60px;"><f7-icon  size="12" style="position: relative; top: -1px;"></f7-icon> {{min2time(travel_min_home)}} uur</div>
          </span>
          </f7-list-item>


          </f7-list>


          </f7-list>

  <!--    doorbetaalde reistijd : {{paid_travel_time}} -->


      <div v-if="loading" class="text-align-center">
        <f7-list media-list>
          <f7-list-item
                  v-for="n in 2"
                  :key="n"
                  :class="`skeleton-text skeleton-effect-blink`"
                  style="text-align: left;"
                  title="Full Name"
                  subtitle="Nog geen tijden opgegeven"
                  after="14:00 - 17:00">
            <f7-skeleton-block style="width: 20px; height: 20px; border-radius: 50%" slot="media"></f7-skeleton-block>
          </f7-list-item>
        </f7-list>

        <f7-preloader></f7-preloader>
      </div>

      <f7-list>

        <!--<f7-list-item-->

                <!--title="Kleine pauze (15 min)">-->
          <!--<f7-toggle-->
                     <!--:checked="workday.mornBreak == 1 ? true : false"-->
                     <!--@change="workday.mornBreak = $event.target.checked ? 1 : 0"-->
                     <!--slot="after"></f7-toggle>-->
        <!--</f7-list-item>-->

        <!--<f7-list-item-->
                <!--title="Lunchpauze (30 min)">-->
          <!--<f7-toggle :checked="workday.lunch == 1 ? true : false"-->
                     <!--@change="workday.lunch = $event.target.checked ? 1 : 0"-->
                     <!--slot="after"></f7-toggle>-->
        <!--</f7-list-item>-->

        <!--<f7-list-item-->

                <!--title="Middagpauze (15 min)">-->
          <!--<f7-toggle :checked="workday.afternBreak == 1 ? true : false"-->
                     <!--@change="workday.afternBreak = $event.target.checked ? 1 : 0"-->
                     <!--slot="after"></f7-toggle>-->
        <!--</f7-list-item>-->



        <f7-list-input
                :value="workday.comments"
                @input="workday.comments = $event.target.value"
                type="textarea"
                label="Opmerkingen"
                placeholder="Dag opmerkingen"
                resizable
        ></f7-list-input>
      </f7-list>

  <!--    <f7-block >-->
  <!--      <f7-row tag="p">-->
  <!--        &lt;!&ndash;<f7-button class="col">Button</f7-button>&ndash;&gt;-->
  <!--        <f7-button class="col" fill @click="saveWorkday">-->
  <!--          <f7-preloader v-if="saveLoading" color="white"></f7-preloader>-->
  <!--          Opslaan-->
  <!--        </f7-button>-->
  <!--      </f7-row>-->
  <!--    </f7-block>-->

    </div>

    <div v-if="saveLoading">
      Loading
    </div>
  </f7-page>
</template>
<script>

    import routes from '../js/routes.js';
    import moment from 'moment'

    export default {
        data() {

            var times = [];

            for (var i = 0; i < 24; i++) {
                for (var j = 0; j < 60; j += 15) {
                    times.push(i.toString().padStart(2,'0')+':'+j.toString().padStart(2,'0'))
                }
            }

            return {
                times: times,
                saveLoading: false,
                loading: true,
                trackedProjects: [],
                defaultWorkday: {
                    starttime: '09:00',
                    endtime: '17:30',
                    mornBreak: 0,
                    lunch: 1,
                    afternBreak: 1,
                    status: 1,
                    comments: '',
                },
                travel_km_home:0,
                travel_min_home:0,
                paid_travel_time:0
            }
        },
        props: {
            workday: {
                type: Object,
                default: function () {
                    return {
                        starttime: '09:00',
                        endtime: '17:30',
                        mornBreak: 1,
                        lunch: 1,
                        afternBreak: 1,
                        status: 0,
                        comments: '',
                    }
                }
            }
        },
        methods: {
            init() {
                if (localStorage.token) {

                    var that = this;

                    // console.log("Workday: ", this.workday);


                    // console.log(this.times);

                  // https://uren.bloklaan.nl/api/

                  const formData = new FormData();
                  formData.append("date", this.workday.date);

                  this.loading = true;

                   // this.$http.post('/my/WorkdayProjects/overview', formData)
                   //     .then(request => that.getOverviewSuccess(request))
                   //     .catch((err) => that.getOverviewFailed(err))

                  this.loading = false;
                  this.trackedProjects = this.workday.tracked_projects;

                  if (this.trackedProjects) {
                    var countProj = this.trackedProjects.length;

                    if (countProj > 0) {
                      this.travel_km_home = this.trackedProjects[countProj-1].travel_km_home;
                      this.travel_min_home = this.trackedProjects[countProj-1].travel_min_home;

                    }
                  }


                }
            },

            getOverviewSuccess (req) {
              this.loading = false;
              this.trackedProjects = req.data.projects;

                var countProj=req.data.projects.length;
                this.trackedProjects = req.data.projects;
                this.travel_km_home = req.data.projects[countProj-1].travel_km_home;
                this.travel_min_home = req.data.projects[countProj-1].travel_min_home;
            },
            getOverviewFailed (err) {
                console.log(err);
            },

            saveWorkday () {

                const formData = new FormData();
                formData.append("date", moment(this.workday.date).format('DD-MM-YYYY'));
                formData.append("starttime", (this.workday.starttime) ? this.workday.starttime : this.defaultWorkday.starttime);
                formData.append("endtime", (this.workday.endtime) ? this.workday.endtime : this.defaultWorkday.endtime);
                formData.append("mornBreak", this.workday.mornBreak ? true : false);
                formData.append("lunch", this.workday.lunch ? true : false);
                formData.append("afternBreak", this.workday.afternBreak ?  true : false);
                formData.append("status", 0);
                formData.append("comments", (this.workday.comments) ? this.workday.comments : this.defaultWorkday.comments);

                var that = this;

                    //
                    // .then(request => that.saveWorkdaySuccess(request))
                    // .catch((err) => that.saveWorkdayError(err))

              this.$f7.views.main.router.back('/', { force: true, props: {
                    loadingBeforeInit: this.$http.post('/my/workdays/save', formData)
                } });

              this.saveLoading = true;

            },
            saveWorkdaySuccess (req) {
                this.$f7.views.main.router.back('/', { force: true });
            },
            saveWorkdayError (err) {
                alert(err);
            },
            editProject(projID) {
              console.log("edit Project ", projID)
            },
            addProject () {
              this.$f7.views.main.router.navigate('/workday/project/', {
                props: {
                  workday: this.workday
                }
              });

            },
            addTravelTime(tmp1){
              console.log("tmp is ",tmp1);
              var tmp2=this.paid_travel_time;
              //this.paid_travel_time = tmp2 + tmp1;
            },
            min2time(tmp){
              console.log("min2time ",tmp)
              this.addTravelTime(tmp);
              return Math.round((tmp/60)*100)/100;
            }

        },
        mounted() {
            console.log("Mounted Workday: ",this.props, this);
            this.$f7ready((f7) => {
              this.init();
            });
        },

    }
</script>
