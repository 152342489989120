/* global localStorage */

import axios from 'axios'

const API_URL = process.env.API_URL || 'https://uren.bloklaan.nl/api/'

const service = axios.create({
    baseURL: API_URL,
    timeout: 20000 // request timeout
});

// request interceptor

service.interceptors.request.use(
    config => {
        config.headers["Authorization"] = "Bearer " + localStorage.token;
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

export default service